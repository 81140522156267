<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :type="isPasswordVisible ? 'text' : 'password'"
    dense
    hide-details="auto"
    @input="onChangeInput"
    :append-icon="
      isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
    "
    @click:append="isPasswordVisible = !isPasswordVisible"
  ></v-text-field>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";

export default {
  props: {},
  setup(props, { emit }) {
    const isPasswordVisible = ref(false);

    const onChangeInput = (value) => emit("onChangeInput", value);
    return {
      isPasswordVisible,
      onChangeInput,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    };
  },
};
</script>

<style></style>
