<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <Logo />

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="
                require(`@/assets/images/misc/mask-v2-${
                  $vuetify.theme.dark ? 'dark' : 'light'
                }.png`)
              "
            />
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Iniciar sesión! 👋🏻
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
                    <FormTextField
                      :value="formData.email"
                      label="Email"
                      placeholder="Email"
                      :rules="[validators.required, validators.emailValidator]"
                      class="mb-6"
                      @onChangeInput="(v) => onChangeFormInputs(v, 'email')"
                    />

                    <FormTextFieldPassword
                      :value="formData.password"
                      label="Contraseña"
                      placeholder="********"
                      :rules="[validators.required]"
                      class="mb-2"
                      @onChangeInput="(v) => onChangeFormInputs(v, 'password')"
                    />

                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Iniciar
                    </v-btn>

                    <div class="d-flex align-center justify-center flex-wrap">
                      <!-- forget link -->
                      <router-link
                        :to="{ name: 'auth-forgot-password' }"
                        class="mt-3"
                      >
                        Recuperar contraseña
                      </router-link>
                    </div>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <p class="mb-0 me-2">¿Aún no tienes una cuenta?</p>
                  <p class="mt-5">
                    Registrame como
                    <router-link :to="{ name: 'auth-register-company' }">
                      Condominio
                    </router-link>
                    o
                    <router-link :to="{ name: 'auth-register-professional' }">
                      Proveedor
                    </router-link>
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import Logo from "@/components/general/Logo";
import FormTextField from "@/components/forms/TextField";
import FormTextFieldPassword from "@/components/forms/TextFieldPassword";

import { ref, getCurrentInstance, reactive } from "@vue/composition-api";
import { required, emailValidator } from "@core/utils/validation";
import api from "@/services";
import { useRouter } from "@core/utils";

export default {
  components: {
    Logo,
    FormTextField,
    FormTextFieldPassword,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { router, routerWelcome } = useRouter();

    const loading = ref(false);
    const loginForm = ref(null);
    const formData = reactive({
      email: null,
      password: null,
    });

    const onChangeFormInputs = (value, property) => {
      formData[property] = value;
    };

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate();

      if (!isFormValid) return;

      onToggleLoading();

      try {
        const { data: response } = await api.login(formData);
        const { token: accessToken, user, role } = response;

        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem(
            "userData",
            JSON.stringify({ ...user, ...{ role } })
          );
          routerWelcome();
        }
      } catch (error) {
        vm.$alert("Credenciales invalidas, intente nuevamente", null, "error");
      }

      onToggleLoading();
    };

    return {
      handleFormSubmit,
      formData,
      loading,

      validators: {
        required,
        emailValidator,
      },

      // Template Refs
      loginForm,

      onChangeFormInputs,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/preset/preset/pages/auth.scss";
</style>
